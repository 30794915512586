.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #ffffff90;
  backdrop-filter: blur(30px);
  color: #636363;
}
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}
.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}
.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}
.cs_video_open {
  cursor: pointer;
}
.cs_hero.cs_style_1 {
  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}
.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}
.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}
.cs_rating {
  i {
    display: flex;
  }
}

.cs_cs_slider_navigation_1 {
  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;
    @media (min-width: 991px) {
      opacity: 0;
    }
  }
  .cs_slider_next {
    right: -35px;
  }
  .cs_slider_prev {
    left: -35px;
  }
  &:hover {
    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}
.cs_cs_slider_navigation_2 {
  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 52px;
  }
}
.cs_cs_slider_navigation_3 {
  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }
    @media (max-width: 991px) {
      display: none !important;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 115px;
    @media (max-width: 1700px) {
      right: 100px;
    }
  }
  .slick-dots {
    display: none !important;
    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}
.cs_related_doctor {
  padding-bottom: 90px;
  @media (max-width: 400px) {
    padding-bottom: 75px;
  }
  .slick-dots {
    bottom: 15px;
  }
}
.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .cs_section_heading.cs_style_1 {
    min-height: 263px;
    @media (max-width: 1700px) {
      min-height: 166px;
    }
    @media (max-width: 991px) {
      min-height: initial;
    }
  }
  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }
    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;
        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }
        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }
      @media (max-width: 991px) {
        grid-column: span 2;
      }
      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}
.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}
.cs_portfolio.cs_style_1 .cs_portfolio_img > div > img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }
  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}
.cs_pagination_box {
  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;
    &:hover {
      color: $accent;
    }
  }
  .cs_pagination_arrow {
    background-color: transparent;
  }
}
.form-check .form-check-input {
  border-color: #636363;
}
.cs_related_doctor .slick-dots {
  display: flex !important;
}
.react-datepicker-wrapper {
  display: block;
}
.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }
  @media (max-width: 1400px) {
    grid-gap: 20px;
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    > * {
      grid-column: span 2;
      &:first-child {
        grid-row: span 3;
      }
    }
  }
}
.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}
.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active + ul {
  display: block;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
/*
* Personalizado
*/


.gl-section h2{
  color: #e866a2;
  font-family: "Montserrat", Sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 2rem;
  margin-top: 20px;
}
.gl-section h3{
  font-family: "Montserrat", Sans-serif;
  font-size: 26px;
  line-height: 26px;
}
/*.gl-section{
  padding: 0px!important;
}*/
.gl-text-container{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding-bottom: 20px!important;

}
.gl-container .gl-text-container{
  color:#54595f!important;
  font-family: "Open Sans", Sans-serif!important;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
.gl-container img{
  margin-bottom: 20px;
  //width: 100%!important;
}

@media (min-width: 992px) {
  .gl-section:first-child {
    flex-basis: 55%!important;
  }

  .gl-section:last-child {
    flex-basis: 40%!important;
  }
}

//Formulario Style 1
.gl-section span{
  padding: 0px!important;
  border: 0px!important;
}
.gl-section section{
  height: 98%;
}

.form-style-1-container {
  position: -webkit-sticky;
  position: sticky;
  top: 200px;

}

.form-style-1 {
  display: flex;
  flex-direction: column;
}

.gl-container .gl-section:first-child {
  padding-right: 30px;
}

.gl-container .gl-section:nth-child(2) {
  padding-left: 30px;
  padding-top: 0px!important;
}
.form-header{
  background-color: transparent;
  background-image: linear-gradient(35deg, #D27EBC, #78ABCF);
  border-radius: 1rem 1rem 0rem 0rem;
  padding: 15px;
  padding-bottom: 1px;
}
.form-header h3{
  margin: 0 0 10px 0;
  color: #ffffff!important;
  font-size: 22px!important;
  font-weight: 800!important;
  line-height: 1.5rem!important;
}
.form-header p{
  color: #ffffff;
  font-family: "Open Sans", Sans-serif!important;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
}
.form-body{
  background-color: transparent;
  background-image: linear-gradient(35deg, #F7B0D7, #B8D0E4);
  border-radius: 0rem 0rem 1rem 1rem;
  padding: 16px;
}
.form-style-1 button{
  background-image: linear-gradient(35deg, #DC89C3, #B29ECB);
  padding: 12px 12px 12px 12px;
  font-family: "Open Sans", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: transparent;
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
}
.form-body .hide-label{
  display: none;
}
.form-body input, .form-body select{
  background-color: #FFFFFF80;
}
.form-group{
  font-size: 16px;
  margin-bottom: 15px;
}
.form-group input.form-control{
  background-color: #ffffff;
  border-radius: 15px;
}
.form-group .selected-flag{
  background-color: #ffffff!important;
}
.react-tel-input .form-control{
  width: 100%;
}
.form-control{
  padding: .282rem .75rem;
}
.checkbox-group{
  color: #ffffff;
}
.checkbox-option input{
  margin-right: 10px;
}
.form-group .telefono-input{
  border-radius: 15px;
}
.form-group .selected-flag, .form-group .flag-dropdown {
  border-radius: 15px 0px 0px 15px;
}
.form-group input{
  border: none;
}
.cs_hero.cs_style_1{
  margin-left: -3px;
}
.cs_site_header.cs_style1.cs_sticky_header.cs_heading_color ul li a{
  color:#ffffff;
}

.cs_site_header.cs_style1.cs_sticky_header.cs_heading_color .menu-item-has-children ul li a{
  color:#274760;
}
.cs_site_header.cs_style1.cs_sticky_header.undefined nav > ul > li a{
  color: #fff;
}
.cs_site_header.cs_style1.cs_sticky_header.cs_active_sticky nav > ul > li a{
  color: #274760;
}
#root > header > div > div > div > div.cs_main_header_left > nav > ul > li > ul > li > a{
  color: #274760;
}


.cs_department_list {
  overflow: hidden;
  width: 100%;
}


@media (max-width: 767px) {
  .cs_menu_toggle{
    color: #274760;
  }
  div.cs_main_header_left > nav > ul > li > a{
    color: #274760!important;
  }
  footer .row{
    flex-wrap: wrap;
  }
  .gl-container{
    padding-left: 16px!important;
  }
  .gl-container h2{
    font-size: 24px;
    line-height: 24px;

  }
  .gl-container h3{
    font-size: 22px;
    line-height: 22px;
    margin-top: 20px;
  }
  .gl-container h4{
    font-size: 20px;
    line-height: 20px;
    margin-top: 20px;
  }
  .gl-container .form-style-1-container{
    position: static;
    padding-right: 20px;
    background-color: #ffffff;
  }
  .gl-section section{
    height: 100%;
  }
  .cs_footer.cs_style_1 .cs_footer_logo_wrap {
    height: calc(100%);
  }
  .cs_footer.cs_style_1 .cs_footer_main{
    padding: 20px 0;
  }
  footer .col-lg-2{
    width: 50%;
  }
  .cs_footer.cs_style_1 .cs_text_widget + .cs_contact_widget{
    margin-top: 20px;
  }
  .cs_footer.cs_style_1 {
    margin-top: 40px;
  }
  .cs_footer.cs_style_1.cs_heading_color{
    margin-top: 0px;
  }

  .gl-container .gl-section:nth-child(2) {
    padding-left: 10px;
  }
}

.row>* {
  flex-shrink: inherit;
}

#root{
  margin-left: -3px;
}
.img-mapas{
  width: 45px!important;
}

.language-switch {
  color: white;
}

.text-white{
  color: white
}


.whats_buttom {

	display: block;

	width: 60px;

	height: 60px;

	position: fixed;

	background-image: url("https://cdn.gestlifesurrogacy.com/img/whatsapp_buttom.svg");

	background-repeat: no-repeat;

	z-index: 99;

	bottom: 18px;

	right: 18px;

	animation: whatsapp 4s linear infinite;

}

.contact-methods-container {
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Centra los elementos verticalmente */
  gap: 10rem;
}

.contact-item-1{
  text-align: center;
  width: 10rem;
  

}

.contact-image-1{
  border-radius: 15px;
  background-color: #d5a1cc;
  margin-bottom: 1rem;
  width: 80%;
  padding: 1.5rem;
}

.contact-item-2{
  text-align: center;
  width: 10rem;
  
}

.contact-image-2{
  border-radius: 15px;
  background-color: #c5a8cf;
  margin-bottom: 1rem;
  width: 80%;
  padding: 1.5rem;

}

.contact-item-3{
  text-align: center;
  width: 10rem;
  
  
}

.contact-image-3{
  border-radius: 15px;
  background-color: #b8afd2;
  margin-bottom: 1rem;
  width: 80%;
  padding: 1.5rem;
}

.contact-item-4{
  text-align: center;
  width: 10rem;


}

.contact-image-4{
  border-radius: 15px;
  background-color: #a7b7d5;
  margin-bottom: 1rem;
  width: 80%;
  padding: 1.5rem;
}




.modern-text {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  color: #4a4a49;

}

.pop1 {
  font-size: 16px; /* Tamaño adecuado para los títulos */
  font-weight: 400;
  margin-bottom: 5px; /* Un pequeño margen debajo */

}

.pop2 {
  font-size: 14px; /* Tamaño de texto más pequeño */
  color: #4a4a49; /* Color más suave para el subtítulo */
  font-weight: 600;
}

/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
  .contact-methods-container {
    gap: 2rem; /* Reduce el espacio entre elementos en pantallas más pequeñas */
  }

  .contact-item {
    width: 4rem; /* Ajuste de tamaño para pantallas pequeñas */
  }

  .pop1 {
    font-size: 10px; /* Reducir tamaño de los títulos */
  }

  .pop2 {
    font-size: 10px; /* Reducir tamaño de los subtítulos */
  }
}